import { useState } from "react";
import { IconProps } from "./types";

export const TelegramIcon = ({
    color,
    width,
    height,
    hoverColor
}: IconProps) => {
    const [hover, isHovering] = useState<boolean>(false);

    return (
        <svg 
            onMouseEnter={(e) => isHovering(true)}
            onMouseLeave={(e) => isHovering(false)} 
            width={width} height={height} viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M38.0506 0.424193C20.5216 2.87618 5.901 16.2391 1.23391 34.0757C-0.160346 39.4045 -0.153081 50.6778 1.24844 56.1106C5.41668 72.2689 17.7331 84.721 33.7153 88.9352C39.0489 90.3412 50.223 90.3577 55.51 88.9676C71.4365 84.7786 84.5636 71.2951 87.8824 55.7146C88.4454 53.071 89.1568 49.9121 89.4625 48.6946C90.2023 45.7475 90.1715 40.8086 89.4129 40.8086C89.0799 40.8086 88.8075 40.0404 88.8075 39.1009C88.8075 33.665 84.1888 22.7633 79.2941 16.6461C69.6148 4.54961 53.5981 -1.74991 38.0506 0.424193ZM48.8505 28.8057C41.0256 31.9316 30.6289 36.0601 25.7463 37.9808C20.8643 39.9015 16.5683 41.8393 16.2008 42.2874C14.8556 43.9265 16.3068 45.035 22.5776 47.1577L28.895 49.2969L42.6087 40.4622C61.4327 28.335 61.7493 28.4856 46.4367 42.2794C34.5798 52.9608 35.2972 51.8481 34.5568 60.7012L34.1476 65.5978L35.5963 64.3957C36.393 63.734 38.3134 61.9798 39.8638 60.4968L42.682 57.8005L48.4764 62.4646C54.1218 67.0081 55.5385 67.6728 57.2481 66.5778C58.1423 66.0049 66.6398 24.5463 66.058 23.5939C65.3709 22.4695 62.649 23.2939 48.8505 28.8057ZM8.7297e-05 44.7871C0.00311434 46.807 0.116326 47.5592 0.251332 46.4593C0.385733 45.3594 0.383311 43.7068 0.245278 42.7868C0.106639 41.8669 -0.00354515 42.7673 8.7297e-05 44.7871Z" 
                fill={hover ? hoverColor : color}
                style={{ transition: "fill 0.3s" }}
            />
        </svg>
    )
}
