import { Button } from '../Button';
import { HeroDivider } from '../HeroDivider';
import { DiscordIcon } from '../Icons/DiscordIcon';
import { RedditIcon } from '../Icons/RedditIcon';
import { TelegramIcon } from '../Icons/TelegramIcon';
import { Spacer } from '../Spacer';
import { Title } from '../Title/Title';
import './Hero.css';

interface HeroProps {
    title: string;
    subtitle: string;
    cta: string;
}

export const Hero = ({
    title,
    subtitle,
    cta
}: HeroProps) => {
    return (
        <div className="heroContainer">
            <div className="heroBackdrop" />
            <div className="topCenter">
                <div className="topText">
                    <p className="topTitle">{cta}</p>
                    <Title title={title} style={{ width: "100%", textAlign: "center"}} />
                    <p className="topSubtitle">{subtitle}</p>
                </div>
                <div className="heroButtons">
                    {/* <Button link="https://stellarx.com" title="BUY SHROOMZ" hoverColor="#0B1729" color="#F1CC6F" fontWeight="800" /> */}
                    <Button link="https://www.reddit.com/r/StellarShroomz/" color="#F1CC6F" hoverColor="#F1CC6F" fontWeight="600" icon={
                        <RedditIcon hoverColor="#0B1729"color="#F1CC6F" height="30" width="30" />
                    } />
                    <Button color="#F1CC6F" hoverColor="#F1CC6F" fontWeight="600" icon={
                        <DiscordIcon hoverColor="#0B1729" color="#F1CC6F" height="30" width="30" />
                    } />
                    <Button color="#F1CC6F" hoverColor="#F1CC6F" fontWeight="600" icon={
                        <TelegramIcon hoverColor="#0B1729" color="#F1CC6F" height="30" width="30" />
                    } />
                </div>
                <div className="topImage">
                    <Spacer height={"4rem"} />
                    <img 
                        className="tokenImage"
                        src="/images/logo.png"
                        alt="Stellar Shroomz Token logo"
                        height="200rem"
                        width="200rem"
                    />
                </div>
                <HeroDivider />
            </div>
        </div>
    )
}
